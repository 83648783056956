.message{
    background-color:#F8B113;
    height: 2rem;
    text-align: center;
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}