@import "./variables.scss";

.sticky_table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0 0;

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  td {
    @include font-mulish;
    font-size: 0.875rem;
    font-weight: 400;
    color: $gray-500;
    padding: 0.616rem 0.5rem;
    height: 3.926rem;
    border-bottom: 1px solid $gray-200;
    background: #fff;
    z-index: 1;

    &.sticky_data {
      background: $white-medium;
      position: sticky;
      z-index: 2;
    }

    &.action_data {
      background: $white-medium;
      position: sticky;
      right: 0;
      z-index: 2;
    }

    &:first-child {
      padding-left: 1.5rem;
    }

    &:last-child {
      padding-right: 1.5rem;
    }
  }

  tr:first-child {
    th {
      &.action_header {
        position: sticky;
        right: 0;
      }

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }

  th {
    padding: 1.313rem 0.5rem;
    background: $primary-50;
    @include font-mulish;
    font-size: 0.875rem;
    font-weight: 700;
    color: $gray-500;
    border-bottom: 2px solid $gray-200;

    &.sticky_header {
      position: sticky;
    }
  }

  .th_group {
    padding-bottom: 0.25rem;
    padding-top: 0.5rem;
    border-bottom: 0;

    div {
      border-bottom: 2px solid $gray-200;
    }
  }

  .th_sub,
  .th_sub2 {
    padding: 0.375rem 1.313rem;
    font-size: 0.875rem;
    font-weight: 400;
    min-width: max-content;

    &:first-child {
      padding-left: 0.5rem;
    }

    &:last-child {
      padding-right: 0.5rem;
    }
  }

  .th_sub {
  }

  .th_sub2 {

    &:last-child {
      padding-right: 1.5rem;
    }
  }
}
