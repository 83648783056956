@import "../../styles/variables.scss";

.nav {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 20;
  &.isactive{
    top: 3rem;
  }

  &.fullscreen {
    left: 0;
    top: 0;
  }
}

.page_wrapper {
  padding-top: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
  margin-top: $header-height;
  margin-left: 4rem;

  &.fullscreen {
    margin: 0;
    padding: 0;
    margin-left: 6.25rem;
  }

  &.pinned {
    margin-left: 8rem;
  }

  &.withStickyContent {
    // padding-top: 0;
  }
}
