@import "../../../styles/variables.scss";

.nav {
  background: $primary-900;
  border-radius: 0.5rem;
  min-width: 4rem;
  min-height: 5.786rem;
  display: flex;
  flex-direction: column;

  &.open {
    padding-bottom: 0.5rem;

    .menu_wrapper {
      max-width: 50vw;
    }

    .synergy_icon {
      justify-content: space-between;
    }

    .expand_icon {
      display: none;
    }

    &:not(.pinned) {
      .menu_wrapper {
        animation: growdown 0.6s ease forwards;
      }
    }

    &.fullscreen {
      height: 100vh;

      .menu_wrapper {
        flex-grow: 1;
        justify-content: space-between;
      }

      .menu_list {
        flex-grow: 1;
      }
    }

    &.pinned {
      max-width: 6.25rem;

      .menu_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 100vh;

        .user_section {
          align-self: stretch;
        }
      }

      &.showMore {
        max-width: unset;
      }
    }
  }
}

.synergy_icon {
  width: 100%;
  height: 4.786rem;
  display: flex;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  justify-content: space-between;

  .pin_icon {
    color: white;
    font-size: 1.125rem;
    cursor: pointer;
  }
}

.expand_icon {
  cursor: pointer;
}

.menu_wrapper {
  max-height: 0;
  max-width: 0;
  overflow: hidden;
}

.menu_list {
  align-items: center;
  justify-content: center;

  max-height: 70vh;
  max-width: 50vw;
  padding: 0 0.25rem;

  display: grid;
  grid-template-rows: repeat(auto-fill, 6.5rem);
  grid-auto-flow: column;
  gap: 0 0.25rem;
}

.menu_item {
  width: 5.75rem;
  text-align: center;
  padding: 0.75rem;
  min-height: 0;
  border-radius: 0.375rem;
  cursor: pointer;

  &.more_icon {
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;

    .menu_icon {
      line-height: 1rem;
      margin-bottom: 0.25rem;
      color: $gray-200;
    }

    .menu_title {
      font-size: 1rem;
      color: $gray-200;
    }
  }

  .menu_icon {
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  .menu_title {
    @include font-montserrat;
    font-size: 0.875rem;
    line-height: 0.91rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    justify-content: center;
  }

  &.active {
    background-color: #fff;

    .menu_icon {
      color: $primary-900;
    }

    .menu_title {
      color: $primary-900;
    }
  }
}

.user_section {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgb(110, 113, 148);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user_avatar {
  width: 2.75rem;
  height: 2.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $primary-50;
  border-radius: 50%;
  color: $primary-500;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  cursor: pointer;

  &.selected {
    border: 2px solid $primary-900;
  }

  .logout_menu {
    position: absolute;
    bottom: 3rem;
    left: 0.625rem;
    padding: 0.25rem 0.75rem;
    color: #fff;
    background: $primary-900;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 0.25rem;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}

@keyframes growdown {
  from {
    max-height: 0;
  }

  to {
    max-height: 100vh;
  }
}
