@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@synergy-marine-group/synergy-ui/dist/styles/index.css";
@import "@synergy-marine-group/synergy-ui/dist/styles/tailwind.css";
@import "react-responsive-modal/styles.css";
@import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
@import "react-calendar/dist/Calendar.css";
@import "./variables.scss";
@import "./sticky_table.scss";

body {
  margin: 0;
  padding: 0;
  background: #fdfdff;
  min-height: calc(100vh - $header-height);
}

html.small {
  font-size: 14px;

  .material-icons-filled {
    &.md-small {
      font-size: 1.125rem;
    }

    &.md-medium {
      font-size: 1.5rem;
    }

    &.md-large {
      font-size: 2.25rem;
    }
  }
}

.material-icons {
  user-select: none;
}

.custom-shadow {
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
}

.custom-shadow-md {
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
}

.custom-shadow-sm {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
}

.report_wrapper {
  height: calc(100vh - 20rem);
}

.iframe_wrapper {
  height: calc(100vh - 21rem);
}

.geolocator_wrapper {
  height: calc(100vh - 18rem);
}

.filter_section {
  position: sticky;
  top: 9.375rem;
  z-index: 11;
}

.active_filter{
  position: sticky;
  top: 11rem;
  z-index: 11;
}

button.gm-ui-hover-effect {
  outline: unset !important;
}

::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 100vmax;
  border-radius: 100vmax;
  background: #cecece;
  border: 4px solid #ffffff00;
  background-clip: padding-box;

  &:hover {
    background-color: #838383;
  }
}

.floating-menu {
  a:not([aria-current="page"]) {
    background-color: rgba(51, 56, 105, 1);
  }
}
