@mixin font-montserrat {
  font-family: "Montserrat", "sans-serif";
}

@mixin font-mulish {
  font-family: "Mulish", "sans-serif";
}

$gray-10: #eaeaed;
$gray-50: #d4d4d4;
$gray-100: #bebebe;
$gray-200: #838383;
$gray-500: #4a4a4a;

$primary-50: #e5e6eb;
$primary-500: #6e7194;
$primary-900: #333869;

$secondary-50: #e9eaf3;
$secondary-100: #c8cbe6;
$secondary-500: #868dcd;
$secondary-900: #565fbb;

$green-100: #bcebd5;
$green-500: #21c979;

$amber-50: #f9f2e2;
$amber-100: #f8e3b4;
$amber-200: #f8c657;

$white-light: #fff;
$white-dark: #f1f1f1;
$white-medium: #f0f3fb;
$white-default: #fdfdff;
$white-border: #cecece;

$red-50:#f9e9e8;
$red-100: #f8c8c5;
$red-500:#f5564e;
$red-900: #f88078;


$header-height: 5.75rem;

@mixin transition($property...) {
  transition: $property 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
